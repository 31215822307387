.wrapper {
  flex: 0 0 auto;
  z-index: 3;
}

.inner {
  display: flex;
  gap: 25px;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 20px;

  a {
    line-height: 2em;
  }
}

.logo {
  text-decoration: none;
  opacity: 0.85;
  transition: 0.3s;
  margin-top: 10px;

  @media screen and (min-width: 768px) {
    margin-top: 13px;
  }

  &:hover {
    opacity: 1;
  }

  &:active {
    transform: translateY(1px);
    opacity: 0.7;
  }
}

.searchInputWrapper {
  position: relative;

  svg.search_icon {
    position: absolute;
    top: 12px;
    right: 10px;
    pointer-events: none;
  }
  svg.close_icon {
    display: none;
    width: 15px;
    height: 15px;
    position: absolute;
    top: 14px;
    right: 40px;
  }
  &.opened {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    .input {
      width: 100%;
      max-width: 100%;
    }
    .suggestContainer {
      height: 100%;
      max-height: 100%;
      border: none;
      background: #0b1d26;
    }
    svg.close_icon {
      display: block;
    }
    @media screen and (min-width: 768px) {
      position: relative;
      overflow: visible;
      .input {
        width: auto;
        max-width: 200px;
      }
      .suggestContainer {
        max-height: 240px;
        height: auto;
        width: 100%;
        overflow: scroll;
        position: absolute;
        border: 2px solid #bca169;
        border-radius: 0 0 5px 5px;
        margin-top: -2px;
        background: #0b1d26;
        z-index: 10001;

        &::-webkit-scrollbar {
          display: none;
        }
        &:empty {
          display: none;
        }

        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
      }
      svg.close_icon {
        display: none;
      }
    }
  }
}

.input {
  border: 2px solid rgba(188, 161, 105, 0.6);
  box-sizing: border-box;
  border-radius: 5px;
  background: transparent;
  padding-right: 40px;
  padding-left: 15px;
  line-height: 36px;
  color: white;
  max-width: 200px;
  margin-top: -5px;
  font-weight: normal;
  &:focus {
    box-shadow: none;
    outline: none;
    border-color: #bca169;
  }
}

ul.list {
  display: block;
  padding: 0;
  &:empty {
    display: none;
  }
}

ul li.listItem {
  height: 51px;
  width: 100%;
  overflow: hidden;
  padding: 0.75rem 1rem !important;
  background: #0b1d26;
  font-weight: normal;
  margin-left: 0 !important;
  display: flex;
  align-items: center;
  font-size: 10px;
  @media screen and (min-width: 768px) {
    font-size: 14px;
  }
  @media screen and (min-width: 960px) {
    font-size: 16px;
  }
  @media screen and (min-width: 1440px) {
    font-size: 18px;
  }

  a {
    text-decoration: none;
    color: white;
    display: flex;
    width: 100%;
    overflow: hidden;
    span {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  img {
    width: 24px;
    height: 24px;
    margin-right: 10px;
    @media screen and (min-width: 960px) {
      width: 30px;
      height: 30px;
    }
  }

  &:hover {
    cursor: pointer;
  }
}

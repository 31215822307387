.logo {
  user-select: none;
  width: 135px;
  height: 10px;
  background-size: 135px 10px;
  background-image: url('/static/logo.svg');

  @media screen and (min-width: 768px) {
    width: 135px;
    height: 10px;
    background-size: 135px 10px;
  }

  @media screen and (min-width: 992px) {
    width: 187px;
    height: 13px;
    background-size: 187px 13px;
    margin-top: 0;
  }
}

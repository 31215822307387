.wrapper {
  flex: 0 0 auto;
  padding: 32px 0;
}

.first {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

.left {
  flex: 50%;
}

.logo {
  opacity: 0.8;
  margin-top: 7px;
}

.links {
  flex-flow: row nowrap;
  flex: 50%;
  display: none;
  font-size: 10px;

  @media screen and (min-width: 768px) {
    display: flex;
    padding-left: 10px;
    font-size: 14px;

    nav {
      flex: 50%;
    }
  }

  @media screen and (min-width: 960px) {
    font-size: 16px;
    padding-left: 20px;
    nav:last-child {
      margin-left: -20px;
    }
  }

  @media screen and (min-width: 1440px) {
    font-size: 18px;
  }

  h3 {
    color: #bca169;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      &:not(:first-child) {
        margin-top: 1em;
      }

      a {
        color: #ffffff;
        text-decoration: none;
        position: relative;
        transition: color 0.3s ease;

        &.active {
          color: #bca169;
        }

        &:after {
          content: '';
          display: block;
          position: absolute;
          width: 0%;
          height: 2px;
          bottom: -8px;
          left: 0;
          background-color: #bca169;
          transition: 0.3s ease;
          opacity: 0;
        }

        &:hover,
        &.active {
          &:after {
            width: 100%;
            opacity: 1;
          }
        }
      }
    }
  }
}

.slogan {
  margin-top: 1em;
  line-height: 1.8em;
  font-size: 10px;
  user-select: none;

  @media screen and (min-width: 768px) {
    font-size: 14px;
  }
  @media screen and (min-width: 960px) {
    font-size: 16px;
  }
  @media screen and (min-width: 1440px) {
    font-size: 18px;
  }
}

.copyright {
  opacity: 0.5;
  margin-top: 1em;
  font-size: 12px;

  @media screen and (min-width: 992px) {
    font-size: 16px;
  }

  a {
    color: #ffffff;
    text-decoration: none;
  }
}

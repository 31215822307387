.navigation {
  position: fixed;
  display: none;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #0b1d26;
  z-index: 999;

  &.nav_opened {
    display: flex;
  }

  @media screen and (min-width: 768px) {
    display: block;
    overflow: visible;
    position: inherit;
    background: none;

    & > ul {
      margin: 0;
      height: auto;
      flex-flow: row nowrap;
      display: flex;
    }
  }

  & > ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    font-size: 14px;
    text-align: center;
    flex: 1 0 auto;

    @media screen and (min-width: 768px) {
      font-size: 14px;
      text-align: left;
      flex-direction: row;
      justify-content: flex-end;
    }

    @media screen and (min-width: 960px) {
      font-size: 16px;
    }
    @media screen and (min-width: 1440px) {
      font-size: 18px;
      flex-wrap: nowrap;
    }

    li {
      white-space: nowrap;
      padding: 5px 0;

      @media screen and (min-width: 768px) {
        margin-top: 0;
      }

      &:not(:first-child) {
        @media screen and (min-width: 768px) {
          margin-left: 10px;
        }

        @media screen and (min-width: 960px) {
          margin-left: 16px;
        }

        @media screen and (min-width: 1440px) {
          margin-left: 30px;
        }
      }

      a.link {
        color: #ffffff;
        text-decoration: none;
        position: relative;
        transition: color 0.3s ease;

        &.active {
          color: #bca169;
        }

        &:after {
          content: '';
          display: block;
          position: absolute;
          width: 0%;
          height: 2px;
          bottom: -8px;
          left: 0;
          background-color: #bca169;
          transition: 0.3s ease;
          opacity: 0;
        }

        &:hover,
        &.active {
          &:after {
            width: 100%;
            opacity: 1;
          }
        }
      }
    }
  }
}

$cubic: cubic-bezier(0.4, 0.01, 0.165, 0.99);

.burger_container {
  z-index: 9999;
  display: block;
  height: 32px;
  width: 32px;
  cursor: pointer;
  transform: rotate(0deg);
  transition: all 0.3s $cubic;
  user-select: none;
  -webkit-tap-highlight-color: transparent;

  @media screen and (min-width: 768px) {
    display: none;
  }

  .burger_wrapper {
    width: 18px;
    height: 8px;
    position: relative;
    display: block;
    margin: -4px auto 0;
    top: 50%;

    .bar {
      width: 100%;
      height: 1px;
      display: block;
      position: relative;
      background: #fff;
      transition: all 0.3s $cubic;
      transition-delay: 0s;

      &.topBar {
        transform: translateY(0px) rotate(0deg);
      }

      &.btmBar {
        transform: translateY(6px) rotate(0deg);
      }
    }
  }

  &.burger_opened {
    transform: rotate(90deg);

    .burger_wrapper {
      .bar {
        transition: all 0.4s $cubic;
        transition-delay: 0.2s;

        &.topBar {
          transform: translateY(4px) rotate(45deg);
        }
        &.btmBar {
          transform: translateY(3px) rotate(-45deg);
        }
      }
    }
  }
}

.langSwitcherDesktop {
  display: none;
  margin-right: 20px;
  a {
    text-decoration: none;
    color: white;
  }
  @media screen and (min-width: 768px) {
    display: block;
  }

  @media screen and (min-width: 1440px) {
    margin-top: 5px;
    margin-right: 0;
  }
}

ul li.searchContainer {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  line-height: 44px;

  @media screen and (min-width: 768px) {
    flex-basis: 100%;
    flex-direction: row-reverse;
    justify-content: end;
    align-items: center;
    margin-top: 10px;
  }
  @media screen and (min-width: 1440px) {
    flex-direction: column;
    align-items: end;
    margin-top: 0;
  }
}

.langSwitcherMobile {
  @media screen and (min-width: 768px) {
    display: none;
  }
  margin-left: 50px;
  a {
    text-decoration: none;
    color: white;
  }
}

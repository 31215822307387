.wrapper {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
}

.container {
  composes: container from '@/components/ui/Grid/grid.module.scss';
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  position: relative;
}

.indicators {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 2;

  .iSlides {
    text-align: right;
    position: relative;

    .track {
      position: absolute;
      content: '';
      width: 2px;
      background-color: rgba(255, 255, 255, 0.3);
      right: -8px;
      top: 0;
      height: 100%;
    }

    .thumb {
      position: absolute;
      content: '';
      width: 2px;
      background-color: #ffffff;
      right: -8px;
      top: 0;
      height: 0%;
      transition: height 0.3s ease;
    }

    .i {
      padding: 8px 2px;

      span {
        cursor: pointer;
      }
    }
  }
}

.slide {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: -78px;
}

.paddings {
  padding: 32px 0;

  @media screen and (min-width: 760px) {
    padding: 64px 0 64px 53px !important;
  }

  @media screen and (min-width: 1440px) {
    padding: 64px 0 64px 78px !important;
  }
}

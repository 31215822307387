.header {
  composes: headline from '@/components/ui/Headline/headline.module.scss';

  margin-bottom: 24px;

  &:not(:first-child) {
    margin-top: 24px;
  }

  @media screen and (min-width: 768px) {
    &:not(:first-child) {
      margin-top: 48px;
    }
    margin-bottom: 48px;
    margin-left: -78px;
  }
}

.headline {
  color: #bca169;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 10px;
  letter-spacing: 2px;

  &:before {
    display: inline-block;
    content: '';
    background-color: #bca169;
    width: 27px;
    min-width: 27px;
    max-width: 27px;
    height: 1px;
    margin-right: 16px;
    vertical-align: middle;
  }

  @media screen and (min-width: 768px) {
    font-size: 14px;
    letter-spacing: 2px;

    &:before {
      width: 54px;
      min-width: 54px;
      max-width: 54px;
      height: 2px;
      margin-right: 24px;
    }
  }
  @media screen and (min-width: 960px) {
    font-size: 16px;
    letter-spacing: 4px;
  }
  @media screen and (min-width: 1440px) {
    font-size: 18px;
    letter-spacing: 6px;
  }
}

.main {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;

  &.paddings {
    padding: 32px 0;

    @media screen and (min-width: 768px) {
      padding: 64px 0 64px 78px;
    }
  }
}

.breadcrumb {
  text-transform: capitalize;
  text-decoration: none;
  font-size: 10px;
  @media screen and (min-width: 768px) {
    padding: 64px 0 64px 0;
    font-size: 14px;
  }
  @media screen and (min-width: 960px) {
    font-size: 18px;
  }
  @media screen and (min-width: 1440px) {
    font-size: 20px;
  }
}

.breadcrumbs {
  margin: 25px auto;
  width: 100%;
  max-width: 1440px;
  padding: 0 24px;
  font-size: 10px;
  @media screen and (min-width: 768px) {
    font-size: 14px;
    position: absolute;
    top: 50px;
    max-width: 60%;
    z-index: 5;
  }
  @media screen and (min-width: 960px) {
    font-size: 18px;
    max-width: 65%;
  }
  @media screen and (min-width: 1440px) {
    font-size: 20px;
    position: relative;
    top: 0;
    max-width: 1440px;
  }
}

.wrapper {
  display: flex;
  flex-direction: column-reverse;

  @media screen and (min-width: 992px) {
    flex-direction: row;
    flex-wrap: nowrap;
  }

  .map {
    width: 100%;
    margin-top: 32px;

    overflow: hidden;
    border-radius: 4px;

    [class*='ymaps-2'][class*='-ground-pane'] {
      filter: invert(100%) grayscale(0.9);
      -ms-filter: invert(100%) grayscale(0.9);
      -webkit-filter: invert(100%) grayscale(0.9);
      -moz-filter: invert(100%) grayscale(0.9);
      -o-filter: invert(100%) grayscale(0.9);
    }
  }

  .desc {
    width: 100%;

    @media screen and (min-width: 992px) {
      width: 60%;
      padding-left: 96px;
    }
  }
}

.officesTabs {
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
  margin-bottom: 5px;
  justify-content: left;
  @media screen and (min-width: 768px) {
    justify-content: right;
  }
}

.officeTabTitle {
  font-size: 12px;
  &:not(:last-child) {
    margin-right: 35px;
  }
  &.activeTab {
    border-bottom: 2px solid #bca169;
    padding-bottom: 10px;
  }
  @media screen and (min-width: 768px) {
    font-size: 18px;
  }
  @media screen and (min-width: 960px) {
    font-size: 20px;
  }
  @media screen and (min-width: 1440px) {
    font-size: 24px;
  }
}

.descWrapper {
  display: grid;
  grid-template-columns: 6fr 4fr;
  column-gap: 20px;

  @media screen and (min-width: 768px) {
    grid-template-columns: 3fr 3fr 2fr;
  }

  @media screen and (min-width: 1200px) {
    display: flex;
    justify-content: space-between;
  }
}

.descColumn {
  font-size: 10px;
  .descColumnTitle {
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 12px;
    font-weight: 400;
    @media screen and (min-width: 768px) {
      margin-top: 40px;
      font-size: 18px;
      margin-bottom: 20px;
    }
    @media screen and (min-width: 960px) {
      font-size: 20px;
    }
    @media screen and (min-width: 1440px) {
      font-size: 24px;
    }
  }
  @media screen and (min-width: 768px) {
    font-size: 14px;
  }
  @media screen and (min-width: 960px) {
    font-size: 16px;
    max-width: 300px;
  }
  @media screen and (min-width: 1440px) {
    font-size: 18px;
  }
}

.downloadSchemeButton {
  text-decoration: none;
  width: 100%;
  display: block;
  text-align: center;
}

.descrTop,
.descrBottom {
  display: flex;
  @media screen and (min-width: 960px) {
    flex: 50%;
  }
}

.descrBottom {
  flex-direction: row-reverse;
  @media screen and (min-width: 960px) {
    flex-direction: row;
  }
  .descColumn:first-child {
    padding-left: 45px;
    @media screen and (min-width: 768px) {
      padding-left: 10px;
    }
    @media screen and (min-width: 960px) {
      padding-left: 20px;
    }
  }
  .descColumn:last-child {
    @media screen and (min-width: 768px) {
      padding-right: 30px;
    }
    @media screen and (min-width: 960px) {
      padding-right: 0;
    }
  }
}

.descrTop {
  .descColumn:last-child {
    padding-left: 45px;
    @media screen and (min-width: 768px) {
      padding-left: 10px;
    }
    @media screen and (min-width: 960px) {
      padding-left: 20px;
    }
  }

  .descColumn:first-child {
    @media screen and (min-width: 768px) {
      padding-right: 30px;
    }
    @media screen and (min-width: 960px) {
      padding-right: 0;
    }
  }
}

.paddings {
  @media screen and (min-width: 768px) {
    padding: 64px 0 64px;
  }
}

.header {
  @media screen and (min-width: 768px) {
    margin-left: 0 !important;
  }
}

.schemesColumn {
  position: relative;
}

.schemesListWrapper {
  max-height: 240px;
  width: 100%;
  overflow: scroll;
  position: absolute;
  border: 2px solid #bca169;
  border-radius: 0 0 5px 5px;
  margin-top: -2px;
  background: #0b1d26;
  z-index: 10001;

  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  ul {
    display: block;
    padding-left: 20px;
    margin: 0;
    padding-bottom: 10px;
  }

  ul li {
    width: 100%;
    overflow: hidden;
    padding-top: 10px;
    background: #0b1d26;
    font-weight: normal;
    margin-left: 0 !important;
    display: flex;
    align-items: center;
    font-size: 10px;
    @media screen and (min-width: 768px) {
      font-size: 14px;
    }
    @media screen and (min-width: 960px) {
      font-size: 16px;
    }
    @media screen and (min-width: 1440px) {
      font-size: 18px;
    }

    a {
      text-decoration: none;
      color: white;
      display: flex;
      width: 100%;
      overflow: hidden;
      span {
        overflow: hidden;
        text-overflow: ellipsis;
      }
      cursor: pointer;
    }
  }
}

.schemesList {
}

.schemesListCity {
  line-height: 22px;
  font-weight: 400;
  font-size: 10px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #bca169;
  display: block;
  background: #0b1d26;
  @media screen and (min-width: 768px) {
    font-size: 14px;
  }
  @media screen and (min-width: 960px) {
    font-size: 16px;
    letter-spacing: 4px;
  }
  @media screen and (min-width: 1440px) {
    font-size: 18px;
    letter-spacing: 6px;
  }
}

.schemesListLink {
  font-size: 10px;
  @media screen and (min-width: 768px) {
    font-size: 14px;
  }
  @media screen and (min-width: 960px) {
    font-size: 16px;
  }
  @media screen and (min-width: 1440px) {
    font-size: 18px;
  }
}

.address {
  &:not(:first-child) {
    margin-top: 15px;
  }
}
